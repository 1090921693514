<template>
  <div>
    <v-card-title>Tworzenie dokumentu </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit" @keydown="handleEnterKey">
        <!-- Ustawienia globalne w jednym rzędzie -->
        <v-container class="mt-6">
          <v-card-title>Ustawienia globalne</v-card-title>
          <v-row>
            <v-col cols="12" sm="3">
              <!-- Imię rolnika -->
              <v-text-field v-model="globalFarmerName" label="Imię rolnika"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <!-- Nazwisko rolnika -->
              <v-text-field v-model="globalFarmerSurname" label="Nazwisko rolnika"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <!-- Numer rolnika -->
              <v-text-field v-model="globalFarmerNumber" label="Numer rolnika"></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <!-- Rodzaj żywca -->
              <v-select
                  v-model="globalType"
                  :items="animalTypes"
                  label="Rodzaj żywca dla zaznaczonych sztuk"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <!-- Zaznacz wszystkie sztuki -->
              <v-checkbox v-model="selectAllAnimals" label="Zaznacz wszystkie sztuki bydła" @change="toggleSelectAll"></v-checkbox>
            </v-col>
            <v-col cols="12">
              <!-- Przycisk zastosowania danych -->
              <v-btn @click="applyGlobalSettings">Zastosuj ustawienia</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-card-title>Specyfikacja transportu</v-card-title>
          <v-row>
            <v-container>
              <v-autocomplete
                  v-model="selectedUser"
                  :items="users"
                  item-value="id"
                  item-title="name"
                  label="Wybierz użytkownika"
                  clearable
                  outlined
                  @change="fetchUserData"
              />
            </v-container>
            <v-text-field v-model="formData.carrier_details" label="Dane przewoźnika" required></v-text-field>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                  v-model="formData.carrier_number"
                  label="Numer przewoźnika"
                  :rules="[rules.required, rules.maxLengthCarrier, rules.onlyDigits]"
                  maxlength="8"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                  v-model="formData.herd_headquarters_number"
                  :items="herdHeadquartersNumbers"
                  item-title="herd_headquarters_number"
                  item-value="herd_headquarters_number"
                  label="Numer siedziby stada (dealera)"
                  :rules="[rules.required]"
                  required
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                  v-model="formData.recipient"
                  :items="recipients"
                  item-value="id"
                  item-text="name"
                  prepend-icon="mdi-cow"
                  label="Odbiorca"
                  :rules="[rules.required]"
                  required
                  disabled
              >
                <template v-slot:selection>
                  <span>{{ recipientName }}</span> <!-- Wyświetlanie nazwy zamiast ID -->
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formData.place"
                  label="Miejscowość"
                  prepend-icon="mdi-map-marker"
                  :rules="[rules.required, rules.onlyAlphanumeric]"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formData.transport_time"
                  label="Przewidywany czas transportu (godziny)"
                  prepend-icon="mdi-clock-fast"
                  type="number"
                  :rules="[rules.required, rules.numberRequired, rules.numberPositive]"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formattedDateOfDeparture"
                  label="Data wyjazdu"
                  prepend-icon="mdi-calendar"
                  readonly
                  @click="departureDateMenu = true"
              ></v-text-field>
              <v-dialog
                  v-model="departureDateMenu"
                  max-width="290"
                  class="custom-dialog"
              >
                <v-date-picker
                    v-model="formData.date_of_departure"
                    @change="setDateOfDeparture"
                    :rules="[rules.required]"
                    color="primary"
                ></v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formData.time_of_departure"
                  label="Godzina wyjazdu"
                  prepend-icon="mdi-clock"
                  readonly
                  @click="departureTimeMenu = true"
              ></v-text-field>
              <v-dialog
                  v-model="departureTimeMenu"
                  max-width="290"
                  class="custom-dialog"
              >
                <v-time-picker
                    v-model="formData.time_of_departure"
                    format="24hr"
                    @change="departureTimeMenu = false"
                    color="primary"
                    :rules="[rules.required]"
                ></v-time-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formData.truck_registration_number"
                  label="Numer rejestracyjny ciągnika siodłowego/ciężarówki"
                  prepend-icon="mdi-truck"
                  :rules="[rules.required, rules.maxLength, rules.onlyAlphanumeric]"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="formData.trailer_registration_number"
                  prepend-icon="mdi-truck-plus"
                  label="Numer rejestracyjny naczepy"
                  :rules="[rules.maxLength, rules.onlyAlphanumeric]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card-title>Dodaj sztuki</v-card-title>
              <v-row
                  v-for="(animal, index) in formData.animals"
                  :key="index"
                  align="center"
              >
                <v-col cols="auto">
                  <!-- Checkbox dla każdej sztuki -->
                  <v-checkbox
                      v-model="animal.selected"
                      @change="updateSelectAllStatus"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="auto">
                  <p class="font-weight-bold">
                    <span>{{ index + 1 }}</span>
                  </p>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                      v-model="animal.farmer_name"
                      label="Imię rolnika"
                      :rules="[rules.required, rules.onlyAlphanumeric]"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                      v-model="animal.farmer_surname"
                      label="Nazwisko rolnika"
                      :rules="[rules.required, rules.onlyAlphanumeric]"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                      v-model="animal.farmer_registered_number"
                      label="Numer siedziby stada rolnika"
                      :rules="[rules.required, rules.noSpaces, rules.onlyAlphanumeric]"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                      v-model="formattedDateOfBirth[index]"
                      label="Data urodzenia"
                      prepend-icon="mdi-calendar"
                      @click:prepend="openBirthMenu(index)"
                      @blur="onDateBlur(index)"
                      placeholder="DD.MM.RRRR"
                      :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                      v-model="animal.earring_number"
                      label="Numer kolczyka"
                      :rules="[rules.required, rules.validEarringNumber, rules.noSpaces]"
                      @input="animal.earring_number = $event.target.value.toUpperCase()"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1">
                  <v-select
                      v-model="animal.type"
                      :items="animalTypes"
                      label="Rodzaj żywca"
                      :rules="[rules.required]"
                      required
                  ></v-select>
                </v-col>
                <v-col cols="1">
                  <v-btn icon @click="removeAnimal(index)" v-if="index > 0">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-container>
            <v-btn @click="addAnimal">Dodaj Sztukę</v-btn>
          </v-container>
          <v-btn type="submit" :disabled="!valid" color="primary" @keydown="blockEnter">Generuj Dokument</v-btn>
        </v-container>
      </v-form>
    </v-card-text>
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Sukces</v-card-title>
        <v-divider></v-divider>
        <div class="py-12 text-center">
          <v-icon class="mb-6" color="success" icon="mdi-check-circle-outline" size="200"></v-icon></div>
        <v-card-text class="py-6 text-center">Dokument został wygenerowany pomyślnie!</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="closeDialog" @keydown.enter.prevent>OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Błąd</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-6 text-center">{{ errorMessage }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="closeErrorDialog" @keydown.enter.prevent>Zamknij</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import apiClient from '@/api/apiClient';

export default {
  name: 'CreateDocument',
  data() {
    return {
      users: [],
      selectedUser: null,
      globalFarmerName: '',
      globalFarmerSurname: '',
      globalFarmerNumber: '',
      globalType: '', // Typ do ustawienia globalnego
      animalTypes: ['Buhaj', 'Jałowica', 'Krowa'],
      selectAllAnimals: false, // Zmienna dla checkboxa "Zaznacz wszystkie"
      valid: false,
      departureDateMenu: false,
      departureTimeMenu: false,
      species: ['BYDŁO'],
      recipients: [],
      herdHeadquartersNumbers: [],
      dialog: false,
      errorDialog: false,
      errorMessage: '',
      invalidDates: [], // Array to keep track of invalid dates for each animal
      formData: {
        species: 'BYDŁO',
        carrier_number: '',
        herd_headquarters_number: '',
        recipient: '',
        place: '',
        date_of_departure: new Date(),
        time_of_departure: this.formatTime(new Date()),
        transport_time: '',
        truck_registration_number: '',
        trailer_registration_number: '',
        carrier_details: '',
        headline_id:'',
        animals: [
          {
            farmer_name: '', // New field
            farmer_surname: '', // New field
            farmer_registered_number: '',
            date_of_birth: null,
            earring_number: '',
            type: '',
            birthMenu: false,
            selected: false
          }
        ]
      },

      rules: {
        required: value => !!value || 'To pole jest wymagane.',
        onlyAlphanumeric: value => /^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]*$/.test(value) || 'Numer może zawierać tylko litery, cyfry, polskie znaki i spacje.',
        noSpaces: value => !/\s/.test(value) || 'Numer nie może zawierać spacji.',
        validDate: value => /^\d{2}\.\d{2}\.\d{4}$/.test(value) || 'Wprowadź datę w formacie DD.MM.RRRR',
        numberRequired: value => !!value || 'To pole jest wymagane.',
        numberPositive: value => (value > 0) || 'Wartość musi być większa od 0.',
        maxLength: value => !value || value.length <= 8 || 'Numer rejestracyjny może mieć maksymalnie 8 znaków.',
        maxLengthCarrier: value => (value && value.length <= 8) || 'Numer przewoźnika może mieć maksymalnie 8 cyfr.',
        onlyDigits: value => /^\d*$/.test(value) || 'Numer przewoźnika może zawierać tylko cyfry.',
        validEarringNumber: (value) => {
          if (!value) return 'To pole jest wymagane.';
          if (value.startsWith('PL')) {
            if (value.length !== 14) return 'Numer kolczyka musi mieć 14 znaków.';
            if (value.substring(2, 4) !== '00') return 'Dla numerów zaczynających się od "PL", znaki 3 i 4 muszą być "00".';
            const rest = value.substring(4);
            if (!/^\d{10}$/.test(rest)) return 'Dla numerów zaczynających się od "PL", ostatnie 10 znaków musi być cyframi.';
          }
          if (value.startsWith('SK')) {
            if (value.length !== 11) return 'Numer kolczyka musi mieć 11 znaków.';
            if (value.substring(2, 4) !== '81') return 'Dla numerów zaczynających się od "SK", znaki 3 i 4 muszą być "8" oraz "1".';
            const rest = value.substring(4);
            if (!/^\d{7}$/.test(rest)) return 'Dla numerów zaczynających się od "SK", ostatnie 9 znaków musi być cyframi.';
          }
          if (value.startsWith('EE')) {
            if (value.length !== 12) return 'Numer kolczyka musi mieć 12 znaków.';
            if (value.substring(2, 4) !== '00') return 'Dla numerów zaczynających się od "EE", znaki 3 i 4 muszą być "00".';
            const rest = value.substring(4);
            if (!/^\d{8}$/.test(rest)) return 'Dla numerów zaczynających się od "EE", ostatnie 10 znaków musi być cyframi.';
          }
          if (value.startsWith('DK')) {
            if (value.length !== 13) return 'Numer kolczyka musi mieć 13 znaków.';
            if (value.substring(2, 3) !== '0') return 'Dla numerów zaczynających się od "DK", trzeci znak to "0".';
            const rest = value.substring(3);
            if (!/^\d{10}$/.test(rest)) return 'Dla numerów zaczynających się od "DK", ostatnie 11 znaków musi być cyframi.';
          }
          const prefixesWithNineDigits = ['NL', 'AT', 'LU', 'CZ', 'BE'];
          if (prefixesWithNineDigits.includes(value.substring(0, 2))) {
            if (value.length !== 11) return 'Numer kolczyka musi mieć 11 znaków.';
            const rest = value.substring(2);
            if (!/^\d{9}$/.test(rest)) return 'Dla tego prefiksu, ostatnie 9 znaków musi być cyframi.';
          }
          const prefixesWithTenDigits = ['DE', 'FR', 'HU'];
          if (prefixesWithTenDigits.includes(value.substring(0, 2))) {
            if (value.length !== 12) return 'Numer kolczyka musi mieć 12 znaków.';
            const rest = value.substring(2);
            if (!/^\d{10}$/.test(rest)) return 'Dla tego prefiksu, ostatnie 10 znaków musi być cyframi.';
          }
          const prefixesWithTwelveDigits = ['IT', 'LV', 'IE', 'RO'];
          if (prefixesWithTwelveDigits.includes(value.substring(0, 2))) {
            if (value.length !== 14) return 'Numer kolczyka musi mieć 14 znaków.';
            const rest = value.substring(2);
            if (!/^\d{12}$/.test(rest)) return 'Dla tego prefiksu, ostatnie 12 znaków musi być cyframi.';
          }
          return true; // Poprawny numer
        }
      },


    };
  },
  created() {
    console.log('Komponent został utworzony. Wywołuję fetchUsers()');
    this.fetchUsers();
    this.fetchRecipient();
  },
  computed: {
    formattedDateOfDeparture() {
      return this.formData.date_of_departure
          ? format(new Date(this.formData.date_of_departure), 'dd.MM.yyyy', { locale: pl })
          : '';
    },
    formattedDateOfBirth() {
      return this.formData.animals.map((animal) =>
          animal.date_of_birth
              ? format(new Date(animal.date_of_birth), 'dd.MM.yyyy', { locale: pl })
              : ''
      );
    }
  },
  watch: {
    'formData.animals': {
      handler(newAnimals) {
        newAnimals.forEach((animal, index) => {
          if (animal.type !== 'Buhaj') {
            this.invalidDates[index] = false; // Resetuj walidację dla innych typów
            console.log('Reset walidacji dla:', animal.type, 'Index:', index);
          }
        });
      },
      deep: true
    },
    globalType(newVal) {
      // Nowa wartość globalType jest przypisywana, nie jest potrzebne wywołanie funkcji
      console.log("Wybrano nowy typ:", newVal);
    },
    selectAllAnimals(newVal) {
      // Jeśli `selectAllAnimals` jest zaznaczone, zaznaczamy wszystkie sztuki
      if (newVal) {
        this.formData.animals.forEach((animal) => (animal.selected = true));
      }
    },
    selectedUser(newVal) {
      console.log(`🔄 selectedUser zmienił się na: ${newVal}`);
      this.fetchUserData(); // Automatyczne wywołanie pobierania danych
    },
  },
  methods: {
    async fetchRecipient() {
      try {
        // Pobranie ID zalogowanego użytkownika z JWT
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('❌ Brak tokena w localStorage');
          return;
        }

        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const loggedInUserId = decodedToken.user_id;

        console.log(`🟢 Pobieranie recipienta dla usera ID: ${loggedInUserId}`);

        // Pobranie recipienta w jednym zapytaniu
        const response = await apiClient.get(`/api/user-recipient/${loggedInUserId}`);
        console.log('Dane recipienta:', response.data);

        // Ustawienie wartości w formData
        this.formData.recipient = response.data.id;
        this.recipientName = response.data.name;

        console.log('✅ Zaktualizowane pole recipient:', this.formData.recipient, this.recipientName);
        this.$forceUpdate();

      } catch (error) {
        console.error('❌ Błąd pobierania recipienta:', error);
      }
    },
    async fetchUsers() {
      try {
        console.log('Wywołuję API:', apiClient.defaults.baseURL + '/api/rec-users');

        const response = await apiClient.get('/api/rec-users');
        console.log('Dane API:', response.data);

        this.users = response.data.map(user => ({
          id: user.id,
          name: `${user.name}`
        }));

        console.log('Lista użytkowników:', this.users);

      } catch (error) {
        console.error('Błąd API:', error);
      }
    },
    async fetchUserData() {
      if (!this.selectedUser) {
        console.warn('⚠ Nie wybrano użytkownika – nie pobieram danych.');
        return;
      }

      try {
        console.log(`🟢 Pobieranie danych użytkownika ID: ${this.selectedUser}`);

        const userResponse = await apiClient.get(`/api/rec-users/${this.selectedUser}`);
        console.log('📥 [API] Odpowiedź:', userResponse.data);

        if (!userResponse.data) {
          console.warn('⚠ API nie zwróciło danych użytkownika!');
        } else {
          this.formData.carrier_number = userResponse.data.carrier_number || '';
          this.formData.place = userResponse.data.place || '';
          this.formData.headline = userResponse.data.headline_id || null; // ⬅ Poprawione!
        }

        // Pobranie numerów siedziby stada
        const herdResponse = await apiClient.get(`/api/herd-headquarters/${this.selectedUser}`);
        this.herdHeadquartersNumbers = herdResponse.data.map(hh => ({
          herd_headquarters_number: hh.herd_headquarters_number
        }));

      } catch (error) {
        console.error('❌ Błąd pobierania danych użytkownika:', error);
      }
    }
    ,
    handleEnterKey(event) {
      if (event.key === 'Enter') {
        // Pobierz element, który obecnie ma fokus
        const activeElement = document.activeElement;

        // Jeśli fokus jest na przycisku lub w polu tekstowym, nic nie rób
        if (activeElement.tagName === 'TEXTAREA' || activeElement.tagName === 'BUTTON') {
          return;
        }

        // Zablokuj domyślne zachowanie Enter (zapobiegnie wysyłaniu formularza)
        event.preventDefault();
      }
    },
    blockEnter(event) {
      if (event.key === 'Enter') {
        event.preventDefault(); // Dodatkowe zabezpieczenie dla przycisku
      }
    },
    toggleSelectAll() {
      // Ustawia zaznaczenie `selected` dla każdej sztuki zgodnie ze stanem `selectAllAnimals`
      this.formData.animals.forEach((animal) => (animal.selected = this.selectAllAnimals));
    },
    updateSelectAllStatus() {
      // Sprawdzamy, czy wszystkie sztuki są zaznaczone
      const allSelected = this.formData.animals.every((animal) => animal.selected);

      // Aktualizujemy `selectAllAnimals` tylko w zależności od zaznaczenia wszystkich sztuk, bez ich modyfikacji
      this.selectAllAnimals = allSelected;
    },
    applyGlobalSettings() {
      this.formData.animals.forEach((animal) => {
        if (animal.selected) {
          if (this.globalFarmerName) animal.farmer_name = this.globalFarmerName;
          if (this.globalFarmerSurname) animal.farmer_surname = this.globalFarmerSurname;
          if (this.globalFarmerNumber) animal.farmer_registered_number = this.globalFarmerNumber;
          if (this.globalType) animal.type = this.globalType;
        }
      });
    },
    async fetchHerdHeadquartersNumbers() {
      try {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        if (!token || !user) {
          throw new Error('User not authenticated');
        }

        const response = await apiClient.get('/api/herd_headquarters_numbers', {
          params: {
            user_id: user.id, // Przekazujemy parametry GET w czytelny sposób
          },
        });

        // Logowanie odpowiedzi z serwera
        console.log('Response data:', response.data);

        // Upewnij się, że przypisujesz dane poprawnie
        this.herdHeadquartersNumbers = response.data.map((item) => ({
          id: item.id,
          herd_headquarters_number: item.herd_headquarters_number.toString(), // Zapewnia, że jest to string
        }));

        console.log('Processed herdHeadquartersNumbers:', this.herdHeadquartersNumbers);

      } catch (error) {
        console.error('Błąd podczas pobierania numerów siedziby stada:', error);
        this.errorMessage = 'Wystąpił błąd podczas pobierania numerów siedziby stada.';
        this.errorDialog = true;
      }
    },
    async fetchUserCarrierNumber() {
      try {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        if (!token || !user) {
          throw new Error('User not authenticated');
        }

      } catch (error) {
        console.error('Błąd podczas pobierania numeru przewoźnika użytkownika:', error);
        this.errorMessage = 'Wystąpił błąd podczas pobierania numeru przewoźnika.';
        this.errorDialog = true;
      }
    },
    formatDate(date) {
      return date ? format(new Date(date), 'dd.MM.yyyy', { locale: pl }) : '';
    },
    addAnimal() {
      this.formData.animals.push({
        farmer_name: '',
        farmer_surname: '',
        farmer_registered_number: '',
        date_of_birth: null,
        earring_number: '',
        type: this.selectAllAnimals ? this.globalType : '',
        birthMenu: false,
        selected: this.selectAllAnimals
      });
      this.invalidDates.push(false); // Add corresponding invalid date state
    },
    removeAnimal(index) {
      this.formData.animals.splice(index, 1);
      this.invalidDates.splice(index, 1); // Remove corresponding invalid date state
      this.updateSelectAllStatus();
    },
    setDateOfDeparture(date) {
      this.formData.date_of_departure = date;
      this.departureDateMenu = false;
    },
    setDateOfBirth(index, date) {
      console.log('Ustawianie daty urodzenia:', date);
      console.log('Typ zwierzęcia:', this.formData.animals[index].type);

      this.formData.animals[index].date_of_birth = date;
      this.formData.animals[index].birthMenu = false;
      this.updateFormattedDateOfBirth(index);

      const animalType = this.formData.animals[index].type;
      if (animalType === 'Buhaj') {
        const dateLimit = this.calculateDateLimit('Buhaj');
        this.invalidDates[index] = new Date(date) < dateLimit;
        console.log('Walidacja Buhaj: Data niepoprawna:', this.invalidDates[index]);
      } else {
        this.invalidDates[index] = false;
        console.log('Reset walidacji dla typu:', animalType);
      }
    },
    updateFormattedDateOfBirth(index) {
      // Aktualizuj sformatowaną datę po wyborze z kalendarza
      this.formattedDateOfBirth[index] = format(new Date(this.formData.animals[index].date_of_birth), 'dd.MM.yyyy', { locale: pl });
    },
    openBirthMenu(index) {
      this.formData.animals[index].birthMenu = true;  // Otwórz kalendarz po kliknięciu w ikonę
    },
    closeBirthMenu(index) {
      this.formData.animals[index].birthMenu = false;
    },
    onDateBlur(index) {
      // Sprawdź poprawność ręcznie wpisanej daty
      const rawDate = this.formattedDateOfBirth[index];
      const isValidDate = this.validateManualDate(rawDate);

      if (isValidDate) {
        const [day, month, year] = rawDate.split('.');
        const parsedDate = new Date(`${year}-${month}-${day}`);

        if (!isNaN(parsedDate)) {
          // Zaktualizuj model surowej daty, jeśli format jest poprawny
          this.formData.animals[index].date_of_birth = parsedDate;
        }
      } else {
        // Jeśli data nie jest poprawna, przywróć poprzednią wartość
        this.formattedDateOfBirth[index] = format(new Date(this.formData.animals[index].date_of_birth), 'dd.MM.yyyy', { locale: pl });
      }
    },
    validateManualDate(dateString) {
      // Sprawdź, czy data wpisana ręcznie jest poprawna
      const regex = /^\d{2}\.\d{2}\.\d{4}$/;  // Sprawdzanie formatu DD.MM.RRRR
      return regex.test(dateString);
    },
    formatTime(date) {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    getAnimalCount() {
      return this.formData.animals.length;
    },
    validateDateOfBirth(value, type) {
      console.log('Typ zwierzęcia:', type);
      console.log('Data urodzenia:', value);

      // Jeśli typ to "Krowa", pomijamy walidację i nie zmieniamy danych
      if (type === 'Krowa') {
        console.log('Pomijamy walidację i zmianę daty dla typu Krowa');
        return true;
      }

      // Dla innych typów zwierząt
      const dateLimit = this.calculateDateLimit(type);
      const birthDate = new Date(value);

      if (isNaN(birthDate)) {
        console.log('Niepoprawny format daty:', value);
        return 'Niepoprawny format daty urodzenia.';
      }

      const isValid = birthDate >= dateLimit;
      console.log(`Walidacja dla ${type}: Data ${value}, Limit: ${dateLimit}, Czy poprawna: ${isValid}`);

      return (
          isValid ||
          `Data urodzenia dla ${type === 'Buhaj' ? 'samca' : 'samicy'} nie może być starsza niż 30 miesięcy od dnia bieżącego.`
      );
    },
    calculateDateLimit() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 30); // Odejmujemy 30 miesięcy
      return currentDate;
    },
    calculateMinAgeLimit() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 15); // Odejmujemy 15 miesięcy
      return currentDate;
    },

    generateRandomDateWithinLimit(referenceDate, isTooYoung = false) {
      const randomDays = Math.floor(Math.random() * (21 - 5 + 1)) + 5; // Losowe 5-21 dni
      let newDate = new Date(referenceDate);

      if (isTooYoung) {
        // 🟢 POSTARZANIE: Cofamy datę wstecz o 5-21 dni
        newDate.setDate(newDate.getDate() - randomDays);
      } else {
        // 🔵 ODMŁADZANIE: Przesuwamy datę do przodu o 5-21 dni
        newDate.setDate(newDate.getDate() + randomDays);
      }

      console.log(`Nowa data (${isTooYoung ? "Postarzanie" : "Odmładzanie"}):`, newDate.toISOString().split("T")[0]);
      return newDate;
    },

    async submit() {
      if (this.$refs.form && this.$refs.form.validate()) {
        // Oblicz limit daty sprzed 30 miesięcy
        const dateLimit = this.calculateDateLimit();
        const minValidDate = new Date();
        minValidDate.setMonth(minValidDate.getMonth() - 15);

        const animalsToSend = this.formData.animals.map((animal) => {
          const originalDateOfBirth = new Date(animal.date_of_birth);
          let recalculatedDateOfBirth = null;
          let foreverYoung = 0;
          let wasAged = 0;

          const dateLimit = this.calculateDateLimit(); // Granica 30 miesięcy wstecz
          const minAgeLimit = this.calculateMinAgeLimit(); // Granica 15 miesięcy wstecz

          // 🟢 ODMŁADZANIE – jeśli sztuka jest starsza niż 30 miesięcy
          // ✅ Buhaje i Jałowice mogą być odmładzane
          if (originalDateOfBirth < dateLimit && (animal.type === 'Buhaj' || animal.type === 'Jałowica')) {
            recalculatedDateOfBirth = this.generateRandomDateWithinLimit(dateLimit, false);
            foreverYoung = 1;
          }

              // 🔴 POSTARZANIE – jeśli sztuka jest młodsza niż 15 miesięcy
          // ✅ Krowy i Buhaje mogą być postarzane
          else if (originalDateOfBirth > minAgeLimit && (animal.type === 'Krowa' || animal.type === 'Buhaj')) {
            recalculatedDateOfBirth = this.generateRandomDateWithinLimit(minAgeLimit, true);
            wasAged = 1;
          }

          console.log(`Sztuka: ${animal.earring_number}`);
          console.log(`Typ: ${animal.type}`);
          console.log(`Oryginalna data urodzenia: ${originalDateOfBirth.toISOString().split('T')[0]}`);
          console.log(`Nowa data paszportowa: ${recalculatedDateOfBirth ? recalculatedDateOfBirth.toISOString().split('T')[0] : "BRAK ZMIANY"}`);
          console.log(`Forever young: ${foreverYoung}, Was aged: ${wasAged}`);

          return {
            ...animal,
            date_of_birth: originalDateOfBirth.toISOString().split('T')[0],
            passport_birth: recalculatedDateOfBirth
                ? recalculatedDateOfBirth.toISOString().split('T')[0]
                : originalDateOfBirth.toISOString().split('T')[0],
            forever_young: foreverYoung,
            was_aged: wasAged
          };
        });


        try {
          const user = JSON.parse(localStorage.getItem('user'));
          const token = localStorage.getItem('token');

          if (user && user.id) {
            const animalCount = animalsToSend.length;

            // Wyślij dane do backendu
            const response = await apiClient.post(
                '/api/rec-documents',
                {
                  user_id: this.selectedUser,
                  type: this.formData.species,
                  place: this.formData.place,
                  date_of_departure: this.formData.date_of_departure.toISOString().substr(0, 10),
                  time_of_departure: this.formData.time_of_departure,
                  transport_time: this.formData.transport_time,
                  truck_registration_number: this.formData.truck_registration_number,
                  trailer_registration_number: this.formData.trailer_registration_number,
                  recipient: this.recipientName,
                  selected_carrier_number: this.formData.carrier_number,
                  selected_herd_headquarters_number: this.formData.herd_headquarters_number,
                  animals: animalsToSend,
                  count: animalCount,
                  carrier_details: this.formData.carrier_details,
                  headline: this.formData.headline_id,
                },
                {
                  headers: { Authorization: `${token}` },
                }
            );

            if (response.status === 200) {
              this.dialog = true;
            } else {
              throw new Error('Serwer zwrócił błąd');
            }
          }
        } catch (error) {
          console.error('Błąd podczas zapisywania dokumentu:', error.response?.data || error.message);
          this.errorMessage =
              error.response?.data?.message ||
              'Wystąpił błąd podczas zapisywania dokumentu. Dokument nie został zapisany.';
          this.errorDialog = true;
        }
      } else {
        this.errorMessage = 'Formularz nie jest poprawnie wypełniony.';
        this.errorDialog = true;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm(); // Resetuje formularz po zamknięciu dialogu
      if (this.$refs.form) {
        this.$refs.form.reset(); // Resetuje formularz w kontekście Vuetify
      }
      window.location.reload(); // Automatyczne odświeżenie strony po zamknięciu dialogu
    },
    closeErrorDialog() {
      this.errorDialog = false;
    },
    resetForm() {
      this.formData = {
        species: 'BYDŁO',
        carrier_number: '',
        herd_headquarters_number: '',
        recipient: '',
        place: '',
        date_of_departure: new Date(),
        time_of_departure: this.formatTime(new Date()),
        transport_time: '',
        truck_registration_number: '',
        trailer_registration_number: '',
        carrier_details: '',
        headline_id: '',
        animals: [
          {
            farmer_name: '',
            farmer_surname: '',
            farmer_registered_number: '',
            date_of_birth: null,
            earring_number: '',
            type: '',
            birthMenu: false
          }
        ]
      };
      this.invalidDates = [];  // Resetowanie walidacji dat
    },
  },
  mounted() {
    this.fetchUserCarrierNumber();
    this.fetchHerdHeadquartersNumbers().then(() => {
      console.log('Loaded herdHeadquartersNumbers:', this.herdHeadquartersNumbers);
    });
  }
};
</script>



<style scoped>
.error {
  border: 2px solid red;
}
.custom-dialog .v-dialog__content {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.v-icon {
  cursor: pointer;
  margin: 0 5px;
}
</style>


